<template>
  <div>
    <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
      <el-form ref="dataForm" :rules="rules" :model="formData" label-position="left" label-width="100px" style="width: 400px; margin-left: 50px">
        <el-form-item label="案例分类" prop="typeId">
          <el-select v-model="formData.typeId" filterable default-first-option placeholder="请选择案例分类">
            <el-option v-for="item in typeList" :key="item._id" :label="item.name" :value="item._id"></el-option>
          </el-select>
        </el-form-item>
		<el-form-item label="案例标题" prop="title">
		  <el-input v-model="formData.title" clearable maxlength="60" placeholder="请输入案例标题" />
		</el-form-item>
        <el-form-item label="案例介绍" prop="intro">
          <el-input v-model="formData.intro" clearable maxlength="60" placeholder="请输入案例介绍" />
        </el-form-item>
		<el-form-item label="案例地址" prop="url">
		  <el-input v-model="formData.url" clearable maxlength="60" placeholder="请输入案例地址" />
		</el-form-item>
        <el-form-item label="logo" prop="logo">
          <Imgpond v-model="formData.logo" :count="1" />
        </el-form-item>
        <el-form-item label="是否启用" prop="status">
          <el-switch class="has-text" style="margin-left: 6px" v-model="formData.status" :width="36" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用">></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="padding-left: 140px">
        <el-button size="medium" class="confirm-btn" @click="formVisible = false">取消</el-button>
        <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/utils/cloud'
import imageUpload from '@/components/image-upload/image-upload'

export default {
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      formVisible: false, //表单显示状态
      formData: {}, //表单数据
      typeList: [],
      rules: {
        typeId: [{ required: true, message: '请选择案例分类', trigger: 'blur' }],
        title: [{ required: true, message: '请输入案例标题', trigger: 'blur' }],
        intro: [{ required: true, message: '请输入案例介绍', trigger: 'blur' }],
        url: [{ required: true, message: '请输入案例地址', trigger: 'blur' }],
        logo: [{ required: true, message: '请上传图片', trigger: 'blur' }],
		status: [{ required: true }]
      }
    }
  },
  computed: {
    formTitle() {
      return this.formData._id ? '修改案例' : '添加案例'
    }
  },
  watch: {
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            status: 1
          }
        }else{
		  delete this.formData.cate_name
		}
      } else {
        this.formData = {}
      }
    },
    formData(val) {
    }
  },

  created() {
    this.loadTypeList()
  },
  methods: {
    //加载分类
    async loadTypeList() {
      const response = await http.POST('/jw-admin', {
        module: 'case',
        operation: 'getCategoryList',
        project: JSON.parse(this.project)._id,
		status: 1
      })
      this.typeList = response.data
    },
    //提交
    submit() {
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }
        const response = await http.POST('/jw-admin', {
          module: 'case',
          operation: 'setCase',
          project: JSON.parse(this.project)._id,
          ...this.formData
        })
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.formVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    margin-bottom: 5vh;
    min-width: 880px;
    max-width: 880px;
  }
}
</style>
